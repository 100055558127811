import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'next-auth/client';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

// https://next.chakra-ui.com/docs/getting-started
import { ChakraProvider } from '@chakra-ui/core';

const isServer = typeof window === 'undefined';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    integrations: isServer ? [] : [new Integrations.BrowserTracing()],
    tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE),
  });

  Sentry.setTag('environment', process.env.NODE_ENV);

  if(process.env.NEXT_PUBLIC_GIT_COMMIT_HASH){
    Sentry.setTag('git_commit_hash', process.env.NEXT_PUBLIC_GIT_COMMIT_HASH);
  } else {
    throw new Error(
      'NEXT_PUBLIC_SENTRY_DSN set PLS SET NEXT_PUBLIC_GIT_COMMIT_HASH env var to make debugging life easier'
    );
  }

}

// Use the <Provider> to improve performance and allow components that call
// `useSession()` anywhere in your application to access the `session` object.
// err prop stuff related to https://github.com/vercel/next.js/issues/8592
export default function App({ Component, pageProps, err }) {
  return (
    <ChakraProvider resetCSS>
      <Provider
        // Provider options are not required but can be useful in situations where
        // you have a short session maxAge time. Shown here with default values.
        options={{
          // Client Max Age controls how often the useSession in the client should
          // contact the server to sync the session state. Value in seconds.
          // e.g.
          // * 0  - Disabled (always use cache value)
          // * 60 - Sync session state with server if it's older than 60 seconds
          clientMaxAge: 0,
          // Keep Alive tells windows / tabs that are signed in to keep sending
          // a keep alive request (which extends the current session expiry) to
          // prevent sessions in open windows from expiring. Value in seconds.
          //
          // Note: If a session has expired when keep alive is triggered, all open
          // windows / tabs will be updated to reflect the user is signed out.
          keepAlive: 0,
        }}
        session={pageProps.session}
      >
        <Component {...pageProps} err={err} />
      </Provider>
    </ChakraProvider>
  );
}
